<template>
  <div>
    <v-card :loading="loading" outlined>
      <ListTitle
        :access="false"
        :showBackButton="false"
        :title="$t('pageHeader.comment')"
      />

      <v-card-text>
        <v-layout
          class="pt-2 pl-2 pr-2"
          align-start="align-start"
          justify-center="justify-center"
          row
        >
          <v-avatar size="40px">
            <img
              v-if="currentUser.imageUrl"
              :src="currentUser.imageUrl"
              alt="User"
            />
            <img v-else src="@/assets/images/user.jpeg" alt="User" />
          </v-avatar>
          <v-layout class="ml-2 mr-2" column>
            <v-flex>
              <v-text-field
                class="mt-0 pt-0"
                v-model="comment"
                label="Comment"
                placeholder="Start typing what in in my your mind about this customer"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-flex>
            <v-flex class="pt-0 text-right" v-if="comment">
              <v-btn text="text" @click="cancel">cancel</v-btn>
              <v-btn color="info" @click="saveComment">comment</v-btn>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-divider class="mt-3"></v-divider>
      <v-card-text class="pt-1">
        <v-container class="pa-0" v-if="!loading">
          <v-data-table
            :headers="headers"
            :items="comments"
            :search="search"
            :loading="loading"
            :sort-by="sortBy"
            :page.sync="page"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:top>
              <v-flex class="mt-3" xs12 sm4>
                <v-text-field
                  v-model="search"
                  outlined
                  prepend-inner-icon="search"
                  rounded
                  dense
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
            </template>
            <template v-slot:item="{ item }">
              <tr class="pointer">
                <td class="text-left">
                  {{ item.createdAt | moment("MM/DD/YYYY hh:mm A") }}
                </td>
                <td class="text-left">
                  <div v-if="item.User">{{ item.User.name }}</div>
                  <div v-else>N/A</div>
                </td>
                <td class="text-left">{{ item.message }}</td>
              </tr>
            </template>

            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
              >Your search for "{{ search }}" found no results.</v-alert
            >
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import commentService from "../service.js";
import { mapGetters } from "vuex";

export default {
  props: ["RelationId", "TypeId"],
  data() {
    return {
      comment: null,
      comments: [],
      sortBy: "createdAt",
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      loading: true,
      headers: [
        {
          text: "Date",
          align: "left",
          value: "createdAt",
          width: '20%',
          sortable: true
        },
        {
          text: "User",
          align: "left",
          value: "User.name",
          width: '20%',
          sortable: true
        },
        {
          text: "Message",
          align: "left",
          value: "message",
          sortable: true
        }
      ],
      search: null
    };
  },
  watch: {
    RelationId: {
      immediate: true,
      handler() {
        this.getList();
      }
    }
  },
  computed: {
    ...mapGetters("global", ["currentUser"])
  },
  methods: {
    cancel() {
      this.comment = null;
    },
    save(data) {
      return commentService.create(data).then(() => {
        this.comment = null;
        this.getList();
      });
    },
    saveComment() {
      this.save({
        RelationId: this.RelationId.id,
        message: this.comment,
        TypeId: this.TypeId
      });
    },
    getList() {
      return commentService
        .getAll({ RelationId: this.RelationId.id, TypeId: this.TypeId })
        .then(response => {
          console.log('response', response)
          this.comments = response.data;
          this.loading = false;
          return response;
        });
    }
  }
};
</script>

<style scoped></style>
