<template>
  <v-container fluid grid-list-lg>
    <Create
      :createDialog="createDialog"
      @close="createDialog = false"
      @submit="getList"
    ></Create>
    <Edit
      :editDialog="editDialog"
      @close="editDialog = false"
      @submit="getList"
      :editLead="selectedLead"
    ></Edit>
    <v-card :loading="loading" outlined>
      <ListTitle
        :showBackButton="true"
        :access="checkRightStatus(43)"
        title="Lead"
        @add="add"
      />

      <v-card-title>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              outlined
              prepend-inner-icon="search"
              rounded
              dense
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field
          ></v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <v-select
              dense
              outlined
              @change="getList()"
              rounded
              :items="leadStatus"
              v-model="selectedStatus"
              label="Status"
              item-text="name"
              hide-details
              return-object
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Leads"
        :search="search"
        :loading="loading"
        :sort-by="sortBy"
        :page.sync="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr class="pointer">
            <td class="text-left" @click="profile(item)">
              {{ item.createdAt | moment("from", "now") }}
            </td>
            <td class="text-left" @click="profile(item)">{{ item.name }}</td>
            <td class="text-left" @click="profile(item)">
              {{ item.contactName }}
            </td>
            <td class="text-left" @click="profile(item)">
              {{ item.phoneNumber | USPhoneNumber }}
            </td>
            <td class="text-left" @click="profile(item)">{{ item.email }}</td>
            <td class="text-left" @click="profile(item)">{{ item.status }}</td>
            <td class="justify-end align-center text-right">
              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="menu-button">
                  <v-list-item
                    v-if="checkRightStatus(44)"
                    @click="editLead(item)"
                  >
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="checkRightStatus(45)"
                    @click="deleteLead(item)"
                  >
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import leadService from "../service";
import { mapGetters } from "vuex";

const Create = () => import("./Create");
const Edit = () => import("./Edit");
export default {
  name: "lead-list",
  data() {
    return {
      leadStatus: ["Hot Lead", "Cold", "Dead", "Convert", "Warm Lead"],
      createDialog: false,
      editDialog: false,
      selectedLead: null,
      headers: [
        {
          text: "Created At",
          align: "left",
          value: "createdAt",
          sortable: true,
        },
        {
          text: "Business Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Name",
          align: "left",
          value: "contactName",
          sortable: true,
        },
        {
          text: "Phone Number",
          align: "left",
          value: "phoneNumber",
          sortable: true,
        },
        {
          text: "Email Address",
          value: "email",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      Leads: [],
      selectedStatus: "Hot Lead",
      search: null,
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getList();
    document.title = "smplsale - Lead List";
  },
  components: {
    Create,
    Edit,
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    profile(lead) {
      this.$router.push({ path: `/lead/${lead.id}/profile` });
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      this.editDialog = false;
      this.createDialog = false;
      return leadService
        .getAll({ status: this.selectedStatus })
        .then((response) => {
          this.loading = false;
          // console.log("Leads", response);
          this.Leads = response.data;
          return response;
        });
    },
    editLead(lead) {
      this.selectedLead = lead;
      this.editDialog = !this.editDialog;
    },
    deleteLead(lead) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          leadService.delete(lead.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Lead has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
